import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmPerm';
import ASTable from 'components/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/ASTable';
import PrimaryBox from 'components/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Smarthome Menu // Alarm Server",
  "path": "/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Notify your Home Automation System in case of a Motion Detection",
  "image": "./WebUI_1440p_SearchThumb_Smarthome_Alarmserver.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Smarthome_Alarmserver.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Smarthome Menu // Alarm Server' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Notify your Home Automation System in case of a Motion Detection' image='/images/Search/WebUI_1440p_SearchThumb_Smarthome_Alarmserver.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Smarthome_Alarmserver.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Smarthome/Alarm_Server/' locationFR='/fr/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/' crumbLabel="Alarm Server" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "smarthome-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#smarthome-menu",
        "aria-label": "smarthome menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smarthome Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d01100f8d7f8e626eb02eee7fc797393/610c0/1440p_Settings_Alarm_Alarmserver.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAC1UlEQVQ4y22Ty27bVhRF9Rd12yCSbanmQ1JFy4AiiRIvKVIS/CApPvWwa8c1igRIgRhNBkWBjopMHCB2h0WKTtpZ/6AftwpeV7aTdLDBO+HiOvvwljRN4zY61a0Kzd4E7/mviIsrBucfxnx6hbh4y/T5OxSjxxeff0an06HdbrOxsUGlUqGk67oEqprGV7UqYhKxfHVDdnlN+vId6eXNg1yT/3DD4vKK3nBCq2lg9i26XROj1UbXG5TWhoqioCgqjnCIZiHHJ6ecnp6Rzxdk+Zw0zeQ5STNC/4AgSLHFlIFpMzAdbDHGMPZuDVVVvQc6HlmWs1qtmM/nRFFEkiTEcUwSR8TZkmhxRhafM3b2cWwHa+gwHIykpTR8CBxJYEaW58xmM5kCEi+eEs+/IV6eE6VLnsUr8vCIsSsQVgH9X6CC57nkeS6hhZXv+wjbpm8O6PVNnvT6BEeH/PJ9zrNvXfanFmbfRlgjDKP98cgK7sglTVMJK8YNw5DpdCo/5HmefEZRwpufv+PieIjrCMRQ4NoOu8buvaGqrkd2pV3RX55nEir7SxJ5Lha1XB7z980bfrqIsYYmY0fgjexb4J2hul6KK2HL5eJu7HWXYRBytsrJTjL++e09f71+wUiYuLaNLYoOPzFUpGHRYQEtrAI/wHEcLMuST88R+LND/vzjPb//+ILFwRDTtBBDm9bXDwyLVKtV+dcXHT60C4JALsf3j/CjnDBZ0Td71HUVbacq36tWa2xtbVNaL2QNbLVaEng35nop44lcymSyTzBLUZUdvnz0iHKlIq/cOiXlP7vi6hVAwzDI87kcuzAs7IRl0e/s0e122DP7TA4PaDablMtlNjc3P0hp/bvcGxpym7eGEbPQZ5afEr285uTsFW8n+7w+8FHrOuXHjz8FanqdIqqms6Mo1Go1Go0G9Xqdol9d09DrTfTdLo1mmyeqSlvV2NreliN+DPwXZowSezfG4VIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d01100f8d7f8e626eb02eee7fc797393/e4706/1440p_Settings_Alarm_Alarmserver.avif 230w", "/en/static/d01100f8d7f8e626eb02eee7fc797393/d1af7/1440p_Settings_Alarm_Alarmserver.avif 460w", "/en/static/d01100f8d7f8e626eb02eee7fc797393/7f308/1440p_Settings_Alarm_Alarmserver.avif 920w", "/en/static/d01100f8d7f8e626eb02eee7fc797393/42d56/1440p_Settings_Alarm_Alarmserver.avif 1295w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d01100f8d7f8e626eb02eee7fc797393/a0b58/1440p_Settings_Alarm_Alarmserver.webp 230w", "/en/static/d01100f8d7f8e626eb02eee7fc797393/bc10c/1440p_Settings_Alarm_Alarmserver.webp 460w", "/en/static/d01100f8d7f8e626eb02eee7fc797393/966d8/1440p_Settings_Alarm_Alarmserver.webp 920w", "/en/static/d01100f8d7f8e626eb02eee7fc797393/a8acc/1440p_Settings_Alarm_Alarmserver.webp 1295w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d01100f8d7f8e626eb02eee7fc797393/81c8e/1440p_Settings_Alarm_Alarmserver.png 230w", "/en/static/d01100f8d7f8e626eb02eee7fc797393/08a84/1440p_Settings_Alarm_Alarmserver.png 460w", "/en/static/d01100f8d7f8e626eb02eee7fc797393/c0255/1440p_Settings_Alarm_Alarmserver.png 920w", "/en/static/d01100f8d7f8e626eb02eee7fc797393/610c0/1440p_Settings_Alarm_Alarmserver.png 1295w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d01100f8d7f8e626eb02eee7fc797393/c0255/1440p_Settings_Alarm_Alarmserver.png",
              "alt": "Web User Interface - 1440p Series - Alarm Server",
              "title": "Web User Interface - 1440p Series - Alarm Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ASTable mdxType="ASTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "alarmserver-introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarmserver-introduction",
        "aria-label": "alarmserver introduction permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarmserver Introduction`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Motion_Detection/Alarm_Server/"
        }}>{`INSTAR Alarmserver`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/WQHD_Alarmserver_Custom_Headers/"
        }}>{`Alarmserver with Custom Headers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/Alarmserver_Testing/"
        }}>{`Alarmserver Testing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/Debugging_Alarmserver_Headers/"
        }}>{`Debugging Alarmserver Headers and Queries`}</a></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a59cd24586aaceb39a662cf195d0d5eb/7388e/Alarm_Alarmserver_Testing_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.52173913043477%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7rAAAO6wFxzYGVAAADAElEQVQ4y22T224bVRSG/R7QNPFxxuNT7RaRUHt8TGZ8nNOeo2MnTqUgVSKpqFK1lHIBV4CEhMQbIIGACnGBeLwP7XEdpYiLX3sOa337X2vtnVm8+BnrzV9MX73FfPmW8R2ZN78RffMPo8vv6a6/Zv7V3xgvfuf45o/3ZDz/Fe/1nxhPfySzCM4JnnyO2FwhNs/wpS62EudXJJ/eMDBt8mqNR50TmkcDmp8MU7Xu6FF7hNb4iMx4MkWEMcnpGhFEeL5UiCuC9NkPEzqdDhVNwxcCx7FxHSeVbVvM57NUlmXxoNEgM5/PWS4T4jjCc933EhxrTrDc0BmY1GtVVus1QRgSRRFhGOJ5Ho6Mcxw8IWi1Hm6B8qf8aFl2GuRHS/x4jQiXxGeX6IMTatUqcRzfAv4rmddstrbAIAhwXTeVYRh0ez06epd2R6fX73N0eEi93iBOEhzH/V+gEIJm6x3Q9/20Bzugrut0u3q69vt9DlNgnTA5Tfvous6tAdkm17YRnkdrB5Ql71zK3XbBtm2z2WwYDkdUqlWcYIlhx/hBlDpK+yxNODbTuyVLoHSZBr2D7YBPLi4YjkbpUL57FvDtZw5JKPA8gSsEievxZm7z0vF4KIeyWCyI4jiFSXez2QzTNFMZJyYrf8rjk8eUSyrXyYjrqItxPKDX66N3u4w6OudHbU7bOpVKZQuU09sdAfk+mUy2Msechg662Wfvwz0Oihr3cgqFQpFCIU8um+Ugl+ODXJZ7xRLZfImMLCtJktsSb3soN7AtVmcXHE9m3N/bo1LRKKsKqqpSLpfRNA1NrqpKo15HUVQyC8tC+AGW7WCl4/cJfNmjLTSMY0ajEfv7B2haJU1SFOUWWC5v4WVNo1gskZG34SzxiYSLWCzw5cEOE7wgIXQ9Vr7PcDAge7DPg5pGRdsCU5daBVWroqllaqpKqVgkc7U0+eWLMa9XY572hiSzKc7ll1jXP3Bjhfw0njHX+2il+4h2kXazSFFRUZUSarON8rHBoVLFyBeo5Qv8C9ssNSYXYZvOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a59cd24586aaceb39a662cf195d0d5eb/e4706/Alarm_Alarmserver_Testing_01.avif 230w", "/en/static/a59cd24586aaceb39a662cf195d0d5eb/d1af7/Alarm_Alarmserver_Testing_01.avif 460w", "/en/static/a59cd24586aaceb39a662cf195d0d5eb/7f308/Alarm_Alarmserver_Testing_01.avif 920w", "/en/static/a59cd24586aaceb39a662cf195d0d5eb/3b501/Alarm_Alarmserver_Testing_01.avif 1104w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a59cd24586aaceb39a662cf195d0d5eb/a0b58/Alarm_Alarmserver_Testing_01.webp 230w", "/en/static/a59cd24586aaceb39a662cf195d0d5eb/bc10c/Alarm_Alarmserver_Testing_01.webp 460w", "/en/static/a59cd24586aaceb39a662cf195d0d5eb/966d8/Alarm_Alarmserver_Testing_01.webp 920w", "/en/static/a59cd24586aaceb39a662cf195d0d5eb/ff749/Alarm_Alarmserver_Testing_01.webp 1104w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a59cd24586aaceb39a662cf195d0d5eb/81c8e/Alarm_Alarmserver_Testing_01.png 230w", "/en/static/a59cd24586aaceb39a662cf195d0d5eb/08a84/Alarm_Alarmserver_Testing_01.png 460w", "/en/static/a59cd24586aaceb39a662cf195d0d5eb/c0255/Alarm_Alarmserver_Testing_01.png 920w", "/en/static/a59cd24586aaceb39a662cf195d0d5eb/7388e/Alarm_Alarmserver_Testing_01.png 1104w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a59cd24586aaceb39a662cf195d0d5eb/c0255/Alarm_Alarmserver_Testing_01.png",
              "alt": "Web User Interface - 1440p Series - Alarm Server",
              "title": "Web User Interface - 1440p Series - Alarm Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "alarmserver-use-casestrigger",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarmserver-use-casestrigger",
        "aria-label": "alarmserver use casestrigger permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarmserver Use-Cases:Trigger`}</h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Please note`}</strong>{` that there is a new `}<a parentName="p" {...{
          "href": "/en/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing/"
        }}>{`Alarm Server API for WQHD 2K+ cameras`}</a>{`! The individual steps in the tutorials below are still valid. Only the query is now called `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`&trigger`}</code>{` instead of `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`&active`}</code>{` and the numerical values have changed and now allow the evaluation of multiple, simultaneous alarm triggers!`}</p>
    </blockquote>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Other_Platforms/Loxone_Miniserver/#use-the-loxone-miniserver-as-alarm-server"
        }}>{`Loxone Miniserver`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Other_Platforms/Homematic_IP/#using-the-homematic-ccu3-as-alarmserver-for-our-camera"
        }}>{`Homematic CCU3`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/Homematic_CCU3_and_RedMatic/#instar-ip-camera---homematic"
        }}>{`Homematic & RedMatic`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Other_Platforms/Homee/#camera-alarmserver-and-homee-webhook"
        }}>{`homee`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Other_Platforms/Athom_Homey/#notifying-homey-when-the-camera-alarm-is-triggered-alarm-server"
        }}>{`Homey`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/IFTTT_as_Alarmserver/"
        }}>{`IFTTT`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Linux/Home_Assistant/#using-home-assistant-as-alarmserver-for-your-instar-camera"
        }}>{`Home Assistant`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/Node-RED_and_MQTT/Projects/Alarm_Recording_and_Nightvision/"
        }}>{`Node-RED under Windows`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/Projects/Alarm_Recording_and_Nightvision/"
        }}>{`Node-RED auf einem Raspberry Pi (openHabian)`}</a></li>
    </ul>
    <h2 {...{
      "id": "working-with-alarmserver-queries",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#working-with-alarmserver-queries",
        "aria-label": "working with alarmserver queries permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Working with Alarmserver Queries:`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/Alarmserver_Testing/"
        }}>{`Testing the HTTP Alarmserver`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/Alarmserver_Queries_for_your_Smarthome/"
        }}>{`Alarmserver Queries for your Smarthome`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      